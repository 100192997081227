// import ReactDOM from 'react-dom';
import {Amplify, Storage, API} from 'aws-amplify';
import config from './aws-exports';
// import App from 'App';

Amplify.configure(config);
Storage.configure({track: true});
API.configure(config);

//ReactDOM.render(<App />, document.getElementById(''));
